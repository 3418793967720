import React from "react"

import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { getCurrentLangKey } from "ptz-i18n"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Books = props => {
  const { data } = props
  const { basicPythonCover } = data
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="My books" />
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="md:w-12/12 mb-12">
              <h1 className="mb-4 font-bold text-3xl no-underline">My Books</h1>
              <p>
                These are the books I wrote. If you want to download them, you
                can buy them and pay what you think is worth for them, or just
                read them online without having to worry about any of that.
              </p>
            </div>
          </div>

          <div className="w-full">
            <Link to="/books/basic-python">
              <h2 className="mt-4 mb-4 font-bold text-blue-500 text-xl">
                Basic Python
              </h2>
            </Link>
          </div>
          <div className="w-full flex items-center">
            <div className="md:w-1/12 inline-block">
              <Link to="/books/basic-python">
                <Img
                  fluid={basicPythonCover.childImageSharp.fluid}
                  alt="Felipe Galvão"
                  className="shadow-md"
                />
              </Link>
            </div>
            <div className="md:w-11/12 pl-8 inline-block">
              <p>
                This book teaches the basics of Python. Data structures, basic
                operations, some of the main libraries to do regular day-to-day
                work. Plus, you get to learn how to work with dates, dealing
                with files and handling exceptions. I try to provide lots of
                examples and to write in a language as clear as possible.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    basicPythonCover: file(absolutePath: { regex: "/capa-livro-python.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Books
